<template>
  <div
    class="card-doctor d-flex rounded pa-3"
    :class="{ 'card-doctor--active': active, 'card-doctor--pointer':pointer }"
    @click="$emit('click')"
  >
    <div>
      <img class="card-doctor__avatar" :src="image" :alt="`Avatar ${name}`" />
    </div>
    <div class="card-doctor__body">
      <h6 class="card-doctor__name">{{ name || "-" }}</h6>
      <p class="mb-0 card-doctor__license">{{ license_num || "-" }}</p>
      <p class="mb-0 card-doctor__speciality">{{ speciality || "-" }}</p>
    </div>
    <v-icon color="#00a6b6" class="card-doctor__icon">mdi-check-circle</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "name doctor",
    },
    license_num: {
      type: String,
      default: "123456789",
    },
    speciality: {
      type: String,
      default: "gigi",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.card-doctor {
  width: 100%;
  box-shadow: 0 0 8px $gray_1;
  column-gap: 12px;
  box-sizing: border-box;
  position: relative;
  &--pointer{
    cursor: pointer;
    &:hover{
      background: $gray_2;
    }
  }
  &--active {
    .card-doctor__icon {
      display: block;
    }
  }
  &__icon {
    position: absolute;
    bottom: 14px;
    display: none;
    &::before {
      background: white;
      border-radius: 100%;
    }
  }
  &__avatar {
    width: 60px;
    height: 60px;
    max-height: 60px;
    max-width: 60px;
    object-fit: cover;
    border-radius: 30px;
    font-size: 4px;
  }
  &__body {
    width: 100%;
  }
  &__name {
    font-size: 14px;
    color: $dark_blue;
  }
  &__license {
    font-size: 12px;
    font-weight: 600;
    color: $gray_5;
  }
  &__speciality {
    font-size: 12px;
    font-weight: 500;
    color: $gray_5;
  }
}
</style>
