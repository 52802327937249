<template>
  <v-card class="consult">
    <div class="pa-4 d-flex justify-space-between items-center consult__header">
      <div>
        <h6 class="header__title" v-if="page < 3">
          {{ $t("page.profile.schedule_consultant_online") }}
        </h6>
        <h6 class="header__title" v-else>
          {{ $t("page.profile.consultation_preview") }}
        </h6>
        <p class="header__subtitle mb-0" v-if="page == 1">
          {{ $t("page.profile.select_schedule_consultant") }}
        </p>
      </div>
      <v-icon class="header__btn-close" @click="$emit('closeDialog')">
        mdi-close
      </v-icon>
    </div>
    <v-divider color="00a4b3"></v-divider>
    <div class="consult__body">
      <!-- start page 1 -->
      <v-item-group
        v-model="payload.selectedDoctor"
        class="pa-4 d-flex flex-column body__list-doctor"
        v-if="page == 1"
      >
        <v-item
          v-slot="{ active, toggle }"
          v-for="(item, i) in itemsListDoctor"
          :key="i"
        >
          <card-doctor
            :active="active"
            :image="`${urlhis}${item.photo}`"
            :name="item.practitioner_name"
            :license_num="item.license_number"
            :speciality="item.speciality_name"
            pointer
            @click="toggle"
          >
          </card-doctor>
        </v-item>
      </v-item-group>
      <!-- end page 1 -->
      <!-- start page 2 -->
      <div class="pa-4" v-if="page == 2">
        <card-doctor
          class="mb-4"
          active
          :image="`${urlhis}${selectedDoctor ? selectedDoctor.photo : ''}`"
          :name="selectedDoctor ? selectedDoctor.practitioner_name : ''"
          :license_num="selectedDoctor ? selectedDoctor.license_number : ''"
          :speciality="selectedDoctor ? selectedDoctor.speciality_name : ''"
        >
        </card-doctor>
        <div class="datepicker">
          <v-date-picker
            no-title
            v-model="payload.selected_date"
            :min="minDate"
            :max="maxDate"
            :allowed-dates="allowedDates"
            @update:picker-date="pickerUpdate($event)"
            color="#00a4b3"
            width="100%"
            class="body__date-picker"
            :class="{ 'datepicker--loading': loadDate }"
          >
          </v-date-picker>
          <div class="datepicker__box-loading" v-if="loadDate">
            <v-icon class="datepicker__icon-loading"> mdi-loading </v-icon>
          </div>
        </div>
        <p class="warn-timezone mb-0 mt-3" v-if="testTime.length > 0">
          {{ $t("page.profile.schedule_has_been_convert") }}
          <b>({{ timezoneUser }})</b>
        </p>
        <v-item-group class="body__time-picker" v-model="payload.selected_time">
          <v-item
            v-slot="{ active, toggle }"
            v-for="(time, index) of testTime"
            :key="index"
          >
            <div
              class="mr-3 mt-3 time-picker__item"
              :class="{
                'time-picker__item--active': active,
              }"
              @click="toggle"
            >
              {{ convertTime(time.time, timezoneDoctor, timezoneUser) }}
            </div>
          </v-item>
        </v-item-group>
        <v-checkbox
          v-model="payload.agree_tnc"
          dense
          hide-details
          color="#00a4b3"
        >
          <template v-slot:label>
            <div class="title-tnc">
              {{ $t("readTnc") }}
              <span class="btn-tnc">{{ $t("tncDate") }}</span>
            </div>
          </template>
        </v-checkbox>
      </div>
      <!-- end page 2 -->
      <!-- start page 3 -->
      <div class="body__preview pa-4" v-if="page == 3">
        <h6 class="preview__heading">{{ $t("patientPrev") }}</h6>
        <table class="preview__table">
          <tbody>
            <tr>
              <td>{{ $t("field.name") }}</td>
              <td>: {{ dataProfile.person.name }}</td>
            </tr>
            <tr>
              <td>{{ $t("field.dob") }}</td>
              <td>: {{ formatDateDDMMM(dataProfile.person.date_of_birth) }}</td>
            </tr>
            <tr>
              <td>{{ $t("field.phone_number") }}</td>
              <td>: {{ dataProfile.person.contacts.mobile_phone_number }}</td>
            </tr>
            <tr>
              <td>{{ $t("field.email") }}</td>
              <td>: {{ dataProfile.person.contacts.email }}</td>
            </tr>
          </tbody>
        </table>
        <h6 class="preview__heading mt-2">{{ $t("consultantPreview") }}</h6>
        <table class="preview__table">
          <tbody>
            <tr>
              <td>{{ $t("page.referral.doctor_name") }}</td>
              <td>
                :
                {{ itemsListDoctor[payload.selectedDoctor].practitioner_name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("page.profile.specialist") }}</td>
              <td>
                : {{ itemsListDoctor[payload.selectedDoctor].speciality_name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("dateTime") }}</td>
              <td>
                : {{ formatDateDDMMM(payload.selected_date) }}
                {{ $t("page.profile.at") }}
                {{
                  convertTime(
                    testTime[payload.selected_time].time,
                    timezoneDoctor,
                    timezoneUser
                  )
                }}
                ({{ timezoneUser }})
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end page 3 -->
    </div>
    <v-divider color="00a4b3"></v-divider>
    <div class="consult__footer d-flex justify-end pa-4">
      <c-button @click="backPage()" rounded small outline v-if="page != 1">
        {{ $t("back") }}
      </c-button>
      <c-button
        @click="nextPage()"
        rounded
        small
        :disabled="!checkNextPage"
        v-if="page < 3"
      >
        {{ $t("next") }}
      </c-button>
      <c-button @click="saveConsult()" rounded small v-else>
        {{ $t("save") }}
      </c-button>
    </div>
  </v-card>
</template>

<script>
import { format, parseISO } from "date-fns";
import moment from "moment-timezone";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import CButton from "@/components/v2/button/index.vue";
import CardDoctor from "@/components/v3/cards/card-doctor.vue";
export default {
  components: { CButton, CardDoctor },
  data: () => ({
    page: 1,
    selectedDoctor: null,
    payload: {
      selectedDoctor: null,
      selected_time: "",
      selected_date: "",
      agree_tnc: false,
    },
    availableDates: [],
    listSchedule: [],
    itemsListDoctorApi: [],
    itemsSelectDoctorApi: [],
    itemsListDoctor: [],
    itemsSelectDoctor: {},
    dateBook: "",
    listTimeAvailable: [],
    timeDate: "",
    testTime: [],
    lists: [],
    agreeDate: false,
    dateStart: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    dateSchedule: "",
    timeSchedule: "",
    dateScheduleAvail: false,
    urlhis: "",
    loadDate: true,
    timezoneDoctor: null,
  }),
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "payload.selectedDoctor": function (val) {
      this.selectedDoctor = this.itemsListDoctor[val];
      this.payload.selected_date = "";
    },
    "payload.selected_date": function (val) {
      if (moment(val).isValid()) {
        this.getSchedule(
          val,
          this.itemsListDoctor[this.payload.selectedDoctor].practitioner_id
        );
      } else {
        this.testTime = [];
      }
    },
    // "payload.selected_time": function (val) {
    // console.log(this.testTime[val]);
    // },
  },
  computed: {
    timezoneUser() {
      return moment.tz.guess();
    },
    dateFormatted() {
      return this.formatDate(this.date);
    },
    dataProfile() {
      return this.$store.state.profileData;
    },
    minDate() {
      return moment().add(1, "days").format("YYYY-MM-DD");
    },
    maxDate() {
      return moment().add("3", "months").format("YYYY-MM-DD");
    },
    checkNextPage() {
      switch (this.page) {
        case 1:
          return (
            typeof this.payload.selectedDoctor == "number" &&
            this.payload.selectedDoctor >= 0
          );

        case 2:
          return (
            moment(this.payload.selected_date).isValid() &&
            typeof this.payload.selected_time == "number" &&
            this.payload.selected_time >= 0 &&
            this.payload.agree_tnc
          );
        default:
          return false;
      }
    },
  },
  created() {
    this.urlhis = process.env.VUE_APP_URL_HIS;
    ///api/v1.3/doctor/list
    //this.getListDoctor();
  },
  mounted() {
    this.getData();
  },
  methods: {
    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("HH:mm");

      return formattedTime;
    },
    // end convert time
    // start page action
    nextPage() {
      this.page += 1;
    },
    backPage() {
      this.page -= 1;
    },
    // end page action

    // start save consult
    async saveConsult() {
      try {
        const dataReady = [
          {
            registration_id: this.data.registration_id,
            practitioner_id:
              this.itemsListDoctor[this.payload.selectedDoctor].id,
            practitioner_generate_schedule_id:
              this.testTime[this.payload.selected_time].id,
            start_time: this.testTime[this.payload.selected_time].time,
            free: true,
          },
        ];
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}telemedicine/insert`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (resp.statusCode == 200) {
          this.$swal({
            icon: "success",
            text: this.$t("page.profile.success_schedule_telemedicine"),
          });
          this.$emit("closeDialog");
          this.$emit("refresh");
        } else if (resp.statusCode == 403) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log("Error 503! submit schedule");
      }
    },
    // end save consult

    // date
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate: async function (val) {
      this.loadDate = true;
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}telemedicine/schedule?date=${date}&doctor_id=${this.selectedDoctor.practitioner_id}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.results.length > 0) {
          this.timezoneDoctor = res.results[0].timezone;
          const hasNonEmptyAvailableSchedule = res.results.some(
            result => result.availableSchedule.length > 0
          );

          if (hasNonEmptyAvailableSchedule) {
            availableDates.push(date);
            if (
              this.payload.selected_date == "" &&
              moment().isSameOrBefore(date)
            ) {
              this.payload.selected_date = date;
            }
          }
        }
      }
      this.availableDates = availableDates;
      this.allowedDates();
      this.loadDate = false;
    },
    // end date
    async getData() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}telemedicine/doctors`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.itemsListDoctorApi = resp.results;
        this.itemsListDoctor = this.itemsListDoctorApi;
        //console.log(this.itemsListDoctor,'this.itemsListDoctor')
      }
    },
    async getSchedule(time, idDoctor) {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}telemedicine/schedule?date=${time}&doctor_id=${idDoctor}`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.listSchedule = resp.results;
        if (resp.results.length === 0) {
          this.dateScheduleAvail = false;
          this.testTime = [];
          this.agreeDate = false;

          this.$swal({
            icon: "error",
            text: this.$t("timeAvailable"),
          });
        } else {
          this.dateScheduleAvail = true;
          this.payload.selected_time = "";
          let availableTime = [];
          resp.results.forEach(item => {
            const timeObj = item.availableSchedule.map(time => {
              return { id: item.id, time: time };
            });
            availableTime = [...availableTime, ...timeObj];
          });
          this.testTime = availableTime;
        }
      }
    },
    formatTime(date) {
      return date.substr(0, 5);
    },
    formatDateDDMMM(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("DD MMMM YYYY")
        : moment(date).locale(this.$i18n.locale).format("DD MMMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.datepicker {
  position: relative;
  &__box-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__icon-loading {
    animation: loading-animation 1s infinite;
    font-size: 60px;
  }
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// consult
.consult {
  &__header {
    .header {
      &__title {
        font-size: 16px;
        color: #233f78;
        font-weight: 600;
      }
      &__subtitle {
        font-size: 12px;
      }
      &__btn-close {
        cursor: pointer;
        color: #00a4b3;
      }
    }
  }
  &__body {
    max-height: 70vh;
    overflow: auto;
    .body {
      &__list-doctor {
        row-gap: 16px;
      }
      &__date-picker {
        width: 100%;
        box-shadow: 0 0 8px $gray_1;
      }
      &__time-picker {
        .time-picker {
          &__item {
            display: inline-block;
            padding: 8px 13px;
            border-radius: 6px;
            font-size: 12px;
            cursor: pointer;
            background-color: $gray_7;
            color: $main_2;
            &:hover {
              background: $main_2;
              color: white;
              opacity: 0.8;
            }
            &--active {
              background: $main_2;
              color: white;
            }
            &--dissabled {
              cursor: default;
              background: $dark;
              color: $main_2 !important;
            }
          }
        }
      }
      &__preview {
        .preview {
          &__heading {
            font-size: 14px;
            font-weight: 600;
          }
          &__table {
            width: 100%;
            td {
              font-size: 12px;
              &:nth-child(1) {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
  &__footer {
    column-gap: 16px;
  }
}
.title-tnc {
  font-size: 10px;
  font-weight: 700;
  text-transform: none;
}
.warn-timezone {
  font-size: 10px;
  text-transform: none;
}
.btn-tnc {
  color: #00a4b3 !important;
}
</style>
